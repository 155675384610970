import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/akar-icons_signout.svg";
import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";


import dl_transparent from "../assets/dl_logo_transparent.jpg";


const Landing = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [tabArray, setTabArray] = useState([
    ]);
    const elementRef = useRef();
    const validUserContext = useContext(ValidUserContext);
    
    useEffect(() => {
      console.log("Reading localstorage")
      const items = JSON.parse(localStorage.getItem("tabs"));
      if (items) {
        setTabArray(items);
      }
    }, []);

    useEffect(() => {
        const viz = elementRef.current;
        viz.addEventListener("firstinteractive", async (event) => {
            console.log(`Dashboard Loaded`); 
            var sheets = viz.workbook.publishedSheetsInfo;
            var newArray = sheets.map(sheet => {
                return sheet.name
            }) 
            localStorage.setItem("tabs", JSON.stringify(newArray));
            setTabArray(newArray)   
        });
        viz.addEventListener("tabswitched", async (event) => {
            setActiveTab(tabArray.indexOf(event.detail.newSheetName))
        });
        /*

        */
      }, []);


    const handleTabClick = (tabIndex) => {
        validUserContext.localAuthCheck();
        elementRef.current.workbook.activateSheetAsync(tabArray[tabIndex])
        setActiveTab(tabIndex);
    };

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }
  
    const handleExportPDFClick = () => {
        elementRef.current.displayDialogAsync("export-pdf");
    }

    const handleExportCSVClick = () => {
        elementRef.current.displayDialogAsync("export-csv");
    }

    const handleExportPNGClick = () => {
        elementRef.current.exportImageAsync();
    }

    const handleExportPWRPClick = () => {
        elementRef.current.displayDialogAsync("export-powerpoint");
    }

    const handleTabSwitch = (data) => {
        setActiveTab(data);
    }

    const renderTabs = () => {

      const colors = ['#b1040c', '#e7272e','#ef822b','#fbc917','#a5cd3b','#007632','#3030ef','#0095db']
  
      return tabArray.map((tab, index) => {
        if (activeTab === index) {
            return (
                <span
                  key={index}
                  className={`${classes.tab}  ${classes.active}`}
                  style={{backgroundColor: '#e0e0e0'}}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </span>
            )
        } else {
            return (
                <span
                  key={index}
                  className={`${classes.tab}`}
                  style={{backgroundColor: '#e0e0e0'}}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </span>
            )
        }
      });
    };
    
    var jwtToken = JSON.parse(localStorage.getItem("tableau-login-data"));
    var dashboardURL = JSON.parse(localStorage.getItem("dashboard-url"));;
    dashboardURL = dashboardURL + '?:showVizHome=no&:embed=true&:toolbar=no&:tabs=n'
    return (
      <div className={`${classes.landing}`}>
        <div className={`${classes.toolbar}`}>
            <span className={`${classes.tabs}`}>{renderTabs()}</span>
            <img
            className={classes.icon}
            src={signoutIcon}
            alt="Signout icon"
            htmlFor="sign-out"
            onClick={() => handleLogoutClick()}
            ></img>
            <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span>
            <img
            className={classes.dividericon}
            src={dividerIcon}
            alt="Divider icon"
            htmlFor="divider"
            ></img>
            <img
            className={classes.pdficon}
            src={pdfIcon}
            alt="PDF icon"
            htmlFor="download-pdf"
            onClick={() => handleExportPDFClick()}
            ></img>
            <img
            className={classes.pdficon}
            src={pngIcon}
            alt="PNG icon"
            htmlFor="download-png"
            onClick={() => handleExportPNGClick()}
            ></img>
            <img
            className={classes.pwrpicon}
            src={pwrpIcon}
            alt="Power Point icon"
            htmlFor="download-pwrp"
            onClick={() => handleExportPWRPClick()}
            ></img>
        </div>
        <tableau-viz class={`${classes.tabframe}`} ref={elementRef} id="tableauViz" hide-tabs='true' toolbar='hidden'
                src={dashboardURL}
                token={jwtToken}>
        </tableau-viz>
      </div>
    );
  };
  
  export default Landing;
