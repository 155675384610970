import Layout from "./components/Layout";
import { useRef, useEffect, useContext } from "react";
import classes from "./App.module.scss";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import ErrorPage from "./components/ErrorPage";

import SendEmailForm from "./components/SendEmailForm";

import LoginHead from "./components/LoginHead";
import Landing from "./components/Landing";
import ValidUserContext from "./authCheck";
import { useThirdPartyCookieCheck } from './useThirdPartyCookieCheck';

function App() {
  const validUserContext = useContext(ValidUserContext);
  const cookiesEnabled = useThirdPartyCookieCheck()

  if (validUserContext.isLoggedIn) {
    return (
      <Landing></Landing>
    );
  }

  var form
  if (!cookiesEnabled){
    form = <ErrorPage />
  }
  else if(validUserContext.pwdResetTokenValue) {
    form = <ResetForm />
  } else if (validUserContext.isForgotPwd) {
    form = <SendEmailForm />
  } else {
    form = <LoginForm />
  }

  return (
    <Layout>
      {validUserContext.isLoggingIn? 
        ( <div className={classes.spinnerContainer}>
            <div className={classes.spinner}></div>
          </div>
        ):(
          <div></div>
        )
      }
      <LoginHead />
      {form}
    </Layout>
  );
}

export default App;
