import { createContext, useState } from "react";
import Modal from 'react-modal';
import classes from "./App.module.scss";
import alertIcon from "./assets/akar-icons_alert.svg";
import correctIcon from "./assets/akar-icons_correct.svg";
import { useThirdPartyCookieCheck } from './useThirdPartyCookieCheck';


const ValidUserContext = createContext({
  isLoggedIn: false,
  isLoggingIn: false,
  isForgotPwd: false,
  pwdResetToken: "",
  apiAuthCheck: (enteredEmail, enteredPassword) => {},
  localAuthCheck: () => {},
  forgotPassword: () => {},
  reset: () => {}
});

export const ValidUserContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isForgotPwd, setIsForgotPwd] = useState(false);
  const [pwdResetTokenValue, setPwdResetTokenValue] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState('Error');
  const [modalIcon, setModalIcon] = useState(alertIcon);
  const status = useThirdPartyCookieCheck()


  function openModal(text, isError, autoDismiss) {
    if (isError) {
      setModalIcon(alertIcon);
    } else {
      setModalIcon(correctIcon);
    }
    setModalText(text)
    setIsOpen(true);
    if(autoDismiss) {
      setTimeout(() => {
        closeModal()
      }, "4000");
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    setIsForgotPwd(false);
    window.location.href = "/";
  }

  const customStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.20)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: 'black',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    },
  };


  var queryParameters = new URLSearchParams(window.location.search)
  var resetToken = queryParameters.get("reset")
  if (resetToken != null && resetToken != pwdResetTokenValue) {
    setPwdResetTokenValue(resetToken);
    setIsLoggedIn(false);
    localStorage.removeItem("login-data");
    localStorage.removeItem("dashboard-url");
    localStorage.removeItem("tableau-login-data");
  }

  async function sendPwdResetHandler(username) {
    const url =
      "https://us-west1-diversity-lab-portal.cloudfunctions.net/send-reset-email";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
        {
          "username":username
        });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsLoggingIn(true)
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          openModal("Passwords are hard to remember! Look for an email from diversitydividends@diversitylab.com to reset your password and access your data from the Diversity Dividends Collective. If you don’t hear from us momentarily, please check your spam folder or let us know at diversitydividends@diversitylab.com. \n Thank you!", false, true);
          setIsLoggingIn(false)
        } else {
          setIsLoggingIn(false)
          openModal('Reset Email Failed', true, false);
        }
      })
      .catch((e) => {
        setIsLoggingIn(false)
        openModal('Reset Email Failed', true, false);
      });
  }

  async function apiPwdResetHandler(enteredPassword) {
    const url =
      "https://us-west1-diversity-lab-portal.cloudfunctions.net/create";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    
    var base64Url = pwdResetTokenValue.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var jwt_values= JSON.parse(jsonPayload);

    var raw = JSON.stringify(
        {
          "username":jwt_values.username,
          "password":enteredPassword,
          "token": pwdResetTokenValue
        });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsLoggingIn(true)
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          openModal('Password Updated', false, true);
          setIsForgotPwd(false);
          setIsLoggingIn(false);
        } else {
          openModal('Update Password Failed, please request again a new password reset email', true, false);
          setIsForgotPwd(false);
          setIsLoggingIn(false)
        }
      })
      .catch((e) => {
        openModal('Update Password Failed, please request again a new password reset email', true, false);
        setIsForgotPwd(false);
        setIsLoggingIn(false)
      });
  }

  async function apiAuthCheckHandler(enteredEmail, enteredPassword) {
    const url =
      "https://us-west1-diversity-lab-portal.cloudfunctions.net/login";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"username":enteredEmail,"password":enteredPassword});
      
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    setIsLoggingIn(true)
    await fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const validUsers = [];
        if (data.app_jwt !== undefined) {
          localStorage.setItem("login-data", JSON.stringify(data.app_jwt));
          localStorage.setItem("tableau-login-data", JSON.stringify(data.tableau_jwt));
          localStorage.setItem("dashboard-url", JSON.stringify(data.dashboard_url));
          setIsLoggedIn(data.app_jwt);
        } else {
          openModal('Incorrect Password', true, false);
        }
        setIsLoggingIn(false)
      })
      .catch((e) => {
        setIsLoggingIn(false)
        openModal('Incorrect Password', true, false);
      });
  }

  const localAuthCheckHandler = () => {
    const localData = JSON.parse(localStorage.getItem("login-data"));
    if (localData !== null) {
      var base64Url = localData.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      var jwt_values= JSON.parse(jsonPayload);
      if (Date.now() >= jwt_values.exp * 1000) {
        localStorage.removeItem("login-data");
        localStorage.removeItem("dashboard-url");
        localStorage.removeItem("tableau-login-data");
        setIsLoggedIn(false);
        return false;
      }
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const thirdPartyCookiesErrorHandler = () => {
    openModal('Third party cookies are not enabled in the browser', true, false);
  };

  const logoutUserHandler = () => {
    localStorage.removeItem("login-data");
    localStorage.removeItem("dashboard-url");
    localStorage.removeItem("tableau-login-data");
    setIsLoggedIn(false);
  };

  const forgotPasswordHandler = () => {
    setIsForgotPwd(true)
  };

  const resetHandler = () => {
    setIsForgotPwd(false);
    setPwdResetTokenValue(null)
  };


  const context = {
    isLoggedIn: isLoggedIn,
    isLoggingIn: isLoggingIn,
    isForgotPwd: isForgotPwd,
    pwdResetTokenValue: pwdResetTokenValue,
    apiAuthCheck: apiAuthCheckHandler,
    localAuthCheck: localAuthCheckHandler,
    logoutUser: logoutUserHandler,
    apiPwdReset: apiPwdResetHandler,
    apiSendPwdResetHandler: sendPwdResetHandler,
    forgotPassword: forgotPasswordHandler,
    thirdPartyCookiesError: thirdPartyCookiesErrorHandler,
    reset: resetHandler
  };

  return (
    <ValidUserContext.Provider value={context}>
      {props.children}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img
          className={classes.alerticon}
          src={modalIcon}
          alt="Password icon"
          htmlFor="user-password"
        ></img>
        <div className={classes.modaltext}>{modalText}</div>
      </Modal>
    </ValidUserContext.Provider>
  );
};

export default ValidUserContext;
